







































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { IProject } from '@store/modules/project/Interfaces';
import './scss/PopupReplaceDocuments.scss';

const NSDocuments = namespace('storeProjectDocuments');
const NSProject = namespace('storeProject');

@Component({
    name: 'PopupReplaceDocuments',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseComposite: () => import('@components/BaseComposite/BaseComposite.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
    }
})

export default class PopupReplaceDocuments extends Vue {
    $refs!: { basePopup: HTMLFormElement }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSDocuments.Getter('documentsWithoutRecursion') documentsWithoutRecursion!: IDocument[];
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_DOCUMENT_PARENT) setDocumentParent!: (payload: any) => Promise<void>;

    parentFolder: any = null;
    documentsList: IDocument[] = [];
    selectedDocs: string[] = [];

    get documentsIsFolder() {
        return this.documentsWithoutRecursion.filter(item => item.isFolder);
    }

    childrenIsFolder(folder) {
        return folder.children.filter(item => item.isFolder);
    }

    openPopup(documentsIdsList: string[]) {
        this.selectedDocs = JSON.parse(JSON.stringify(documentsIdsList));
        this.documents.forEach((doc) => {
            if (documentsIdsList.indexOf(doc.uuid) !== -1) {
                this.documentsList.push(doc);
            }
        })
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    onClosePopup() {
        this.selectedDocs = [];
        this.documentsList = [];
    }

    resetParent() {
        this.parentFolder = null;
    }

    setSelectedParent(e) {
        this.parentFolder = e;
    }

    onChangeCheckbox(itemUuid) {
        const index = this.selectedDocs.indexOf(itemUuid);
        if (index === -1) {
            this.selectedDocs.push(itemUuid);
        } else {
            this.selectedDocs.splice(index, 1);
        }
    }

    onSuccess() {
        let selectedDocsReplacePromises = this.selectedDocs.map((documentId) => {
            return this.setDocumentParent({
                appId: this.projectData.id,
                documentId: documentId,
                parentId: this.parentFolder ? this.parentFolder.uuid : ''
            });
        });

        Promise.all(selectedDocsReplacePromises)
            .then(() => {
                this.$emit('replaced')
                this.closePopup();
            });
    }
}
